<template>
  <div>
    <div class="qchat-conv__bubble__docs">
      <div class="q-text-primary">
        <Icons
          :type="
            getFileIcon(
              message.file.filename ? message.file.filename : message.text
            )
          "
          size="45"
        />
      </div>
      <div class="q-ml-2 qchat-conv-docs__wrap">
        <span class="q-mb-0 q-tooltip">
          <span class="q-tooltiptext">
            {{ message.file.filename ? message.file.filename : message.text }}
          </span>
          {{
            sliceWords(
              message.file.filename ? message.file.filename : message.text,
              12
            )
          }}
        </span>
        <span class="q-text-right qchat-conv__bubble__docs__size"
          >{{ countFileSize(message.file) }} MB</span
        >
      </div>
      <div
        class="qchat-conv__bubble__docs__button q-pointer"
        @click="
          downloadFileUrl(
            message.file.url,
            message.file.filename ? message.file.filename : message.text
          )
        "
      >
        <Icons type="la-download" size="22" />
      </div>
    </div>
    <div v-if="message.text">
      <span v-for="(content, idx) in contentFormatter(message.text)" :key="idx">
        <span
        v-dompurify-html="bracketClear(whatsappFormatter(content.textBefore))"
      ></span
        ><a
          :href="
            content.url.includes('://') ? content.url : `https://${content.url}`
          "
          style="word-break: break-all;"
          target="_blank"
          >{{ content.url }}</a
        ><span
        v-dompurify-html="bracketClear(whatsappFormatter(content.textAfter))"
      ></span>
      </span>
    </div>
  </div>
</template>
<script>
const Icons = () => import("../../Icons/index.vue");

export default {
  components: {
    Icons
  },
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    formattedMessage() {
      if (this.isRead)
        return this.whatsappToPreviewFormatter(this.message.text);
      else
        return this.whatsappToPreviewFormatter(
          this.sliceWords(this.message.text, 256)
        );
    }
  },
  methods: {
    downloadFile(file) {
      this.$store.commit("layouts/DISPLAY_TOAST", {
        message: "Downloading (" + this.sliceWords(file.name, 40) + ")"
      });
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const url = URL.createObjectURL(file);
      window.open(url);
    },
    downloadFileUrl(url, filename) {
      this.$store.commit("layouts/DISPLAY_TOAST", {
        message: "Downloading (" + this.sliceWords(filename, 40) + ")"
      });
      window.open(url);
    },
    getFileIcon(type) {
      type = type.split(".")[1];
      if (type) {
        const master = "la-file-";
        if (type.includes("pdf")) return master + "pdf";
        else if (type.includes("excel") || type.includes("spreadsheet"))
          return master + "excel";
        else if (type.includes("word")) return master + "word";
        else if (type.includes("ppt") || type.includes("presentation"))
          return master + "powerpoint";
        else if (type.includes("excel")) return master + "excel";
        else if (type.includes("csv")) return master + "csv";
        else if (type.includes("zip")) return master + "archive";
        else return master + "alt";
      } else return "";
    },
    countFileSize(file) {
      let size = 0;
      if (file.file) size = file.file.size;
      else size = file.size;
      return (size / 1000000).toFixed(2);
    }
  }
};
</script>
